export const Language = [
  "Mandarin Chinese",
  "Spanish",
  "English",
  "Hindi",
  "Bengali",
  "Portuguese",
  "Russian",
  "Japanese",
  "Western Punjabi",
  "Marathi",
  "Telugu",
  "Turkish",
  "Korean",
  "French",
  "German",
  "Vietnamese",
  "Tamil",
  "Urdu",
  "Italian",
  "Arabic",
  "Persian (Farsi)",
  "Polish",
  "Ukrainian",
  "Romanian",
  "Dutch",
  "Greek",
  "Hungarian",
  "Hebrew",
  "Swedish",
  "Czech",
  "Javanese",
  "Thai",
  "Gujarati",
  "Kannada",
  "Malay/Indonesian",
  "Burmese",
  "Amharic",
  "Somali",
  "Hausa",
  "Igbo",
  "Yoruba",
  "Zulu",
  "Xhosa",
  "Afrikaans",
  "Serbian",
  "Croatian",
  "Bosnian",
  "Bulgarian",
  "Slovak",
  "Finnish",
];
